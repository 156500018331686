#header .left-side {
	float: none;
}

/* Snackbar */
.mdc-snackbar {
	margin: 0;
	left: 1.2vw;
	bottom: 2vh;
	z-index: 100;
}

.mdc-snackbar__surface {
	background-color: #43a047;
	min-width: 300px;
}

.mdc-snackbar.error .mdc-snackbar__surface {
	background-color: red;
	min-width: 300px;
}

.mdc-button {
	color: white !important;
	display: flex;
	justify-content: center;
	background-color: rgba(0,0,0,0.05);
	margin-right: 5px;
	width: 29px;
	height: 29px;
	border-radius: 50%;
}

.mdc-button:hover {
	transition: background-color 0.3s;
	background-color: rgba(0,0,0,0.15);
}

/* Notification inside auth modal */
.auth-notification {
	margin-top: -50px;
	margin-left: -35px;
	margin-right: -35px;
	border-radius: 0;
}

/* Search bar */
.video-container {
	background-image: url('/images/home-background.jpg');
}
#select-category {
	flex: 0.4;
}

/* Dashboard container height fix */
/* .dashboard-container {
	height: auto;
} */
.dashboard-content-container {
	padding-bottom: 50px;
}

.switch-button {
	position: relative;
	top: 4px;
}

/* form */
.form-input-error {
	margin-left: 10px;
	color: red;
}

.invalid {
	border-color: red !important;
}